import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.webp'


const _hoisted_1 = { class: "block mt-20 overflow-hidden w-screen footer text-gray-400 sticky" }
const _hoisted_2 = { class: "footer gap-4 p-10 bg-tc-bglight" }
const _hoisted_3 = { class: "my-4 md:my-0" }
const _hoisted_4 = { class: "rounded-3xl" }
const _hoisted_5 = { class: "pt-4" }
const _hoisted_6 = { class: "mb-4 md:mb-0" }
const _hoisted_7 = { class: "footer-title" }
const _hoisted_8 = { class: "mb-4 md:mb-0" }
const _hoisted_9 = { class: "footer-title" }
const _hoisted_10 = { class: "footer-title" }

import {onMounted, ref} from 'vue';
import LanguageSelector from "@/components/Locale/LanguageSelector.vue";
import useTranslation from "@/composable/translation/useTranslation";
import useCustomStore from "@/composable/custom/useCustomStore";
import SolutionsCarousel from "@/components/services/SolutionsCarousel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const {t} = useTranslation();
const copyRightText = ref('');
const {isFullCookieConsentAllowed} = useCustomStore();

function getCopyrightText() {
  const currentYear = new Date().getFullYear();
  copyRightText.value = "©" + " " + currentYear + " by " + process.env.VUE_APP_NAME;
}

onMounted(() => {
  getCopyrightText();
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(SolutionsCarousel, {
        "main-css": "w-screen mb-24",
        "swiper-slide-css": "mx-4",
        "header-text-active": true,
        "header-text": _ctx.$t('services.choose_product'),
        "small-images": true
      }, null, 8, ["header-text"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex" }, [
        _createElementVNode("img", {
          loading: "lazy",
          alt: "Logo",
          height: "30",
          src: _imports_0,
          style: {"align-self":"center"},
          width: "30"
        }),
        _createElementVNode("p", { class: "text-gray-500 text-left" }, [
          _createTextVNode("theconcept technologies, LLC"),
          _createElementVNode("br"),
          _createTextVNode(),
          _createElementVNode("span", { class: "text-gray-400" }, " together for the better ")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(LanguageSelector)
        ]),
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"flex mt-2 gap-4\"><a class=\"link link-hover\" href=\"https://www.facebook.com/theconcept.technologies.llc\"><img loading=\"lazy\" class=\"text-gray-500\" src=\"/img/socialMedia/facebook.svg\" alt=\"theconcept technologies social facebook\"></a><a class=\"link link-hover\" href=\"https://www.instagram.com/theconcept_technologies/\"><img loading=\"lazy\" src=\"/img/socialMedia/instagram.svg\" alt=\"theconcept technologies social instagram\"></a><a class=\"link link-hover\" href=\"https://www.linkedin.com/company/theconcept-technologies-llc/\"><img loading=\"lazy\" src=\"/img/socialMedia/linkedIn.svg\" alt=\"theconcept technologies social LinkedIn\"></a><a class=\"link link-hover\" href=\"https://www.reddit.com/r/theconcepttechnologie/\"><img src=\"/img/socialMedia/reddit.svg\" alt=\"theconcept technologies social reddit\"></a></div>", 1)),
        _withDirectives(_createElementVNode("div", _hoisted_5, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "trustpilot-widget",
            style: {"border-radius":"10px","padding":"10px"},
            "data-locale": "en-US",
            "data-template-id": "5419b6a8b0d04a076446a9ad",
            "data-businessunit-id": "63459310865346305f41efa6",
            "data-style-height": "25px",
            "data-style-width": "100%",
            "data-theme": "light",
            "data-min-review-count": "0",
            "data-style-alignment": "center"
          }, [
            _createElementVNode("a", {
              href: "https://www.trustpilot.com/review/theconcept-technologies.com",
              class: "text-white",
              target: "_blank",
              rel: "noopener"
            }, "rate or view us on Trustpilot")
          ], -1)
        ]), 512), [
          [_vShow, _unref(isFullCookieConsentAllowed)]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex pt-2" }, [
          _createElementVNode("a", {
            href: "https://www.wko.at/stmk",
            class: "link link-hover",
            target: "_blank",
            rel: "noopener"
          }, [
            _createElementVNode("img", {
              loading: "lazy",
              alt: "Logo",
              class: "grayscale hover:grayscale-0",
              height: "30",
              src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/wko_logo.png",
              style: {"align-self":"center"},
              width: "100"
            })
          ])
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('footer.service')), 1),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/ai-integration"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('aiIntegration.Title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/web-development"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('webDevelopment.Title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/funding"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('navbar.FundingBtn')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/ar-vr-development"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('arVrDevelopment.Title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/api"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('api.Title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/product-development"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('productDevelopment.Title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/branding"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('branding.Title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('footer.company')), 1),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/company"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.aboutUs')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/investor-area"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('navbar.InvestmentBtn')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/contact"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.contact')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/press-kit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.press')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/jobs"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.jobs')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('footer.legal')), 1),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/terms-of-use"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.terms')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/privacy-policy"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.privacy')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/imprint"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.imprint')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "link link-hover",
          to: "/cookie-policy"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('footer.cookies')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})