<template>
  <div class="mx-auto py-12 pb-32 px-6 max-w-5xl sm:px-6 lg:px-8 lg:py-24">
    <WizzardDialogOverlay @cancel-confirm="wizzardVisible = false" :wizzard-type="wizzardType" :visible="wizzardVisible"></WizzardDialogOverlay>
<div class="rounded-3xl overflow-hidden">
	<figure class="relative max-h-48 overflow-hidden">
		<img class="w-full" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/contact-banner.webp" alt="Banner" loading="lazy">
	</figure>
    <div class="max-w-[100em] px-7 py-8 lg:p-12 justify-self-center shadow-xl bg-tc-bglightest">
		<div class="items-center  lg:max-w-none lg:flex">
          <div class="flex-1 bg-tc-bglightest pr-6 lg:pr-12">
            <h2 id="Title3WebDevelopment"
                class="text-4xl mb-6 tracking-tight font-extrabold text-gray-300 sm:text-3xl xl:text-5xl">
				{{ $t('services.request.title') }}
			</h2>
            <div class="">
              <div class="flex items-center">
                <h3 class="flex-shrink-0 py-0.5 px-4 bg-tc-bglight text-xs sm:text-sm tracking-wider font-semibold uppercase text-gray-200">
                  {{ $t('solutions.SolutionServiceDetail') }}
                </h3>
                <div class="flex-1 border-t-2 border-tc-bglight"/>
			  </div>
            </div>
			  <div class="mt-4 text-md text-white">
				  {{ $t('solutions.SolutionService') }}
			  </div>
			  <div class="mt-2 text-md text-white">
				  {{ $t('services.request.description') }}
			  </div>

          </div>
			<button @click="openWizzard"
			   class="btn cursor-pointer mt-6 lg:mt-0 no-underline flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold rounded-3xl text-white bg-tc-blue hover:bg-gray-500">{{
					$t('webDevelopment.ServiceBtn')
				}}</button>
        </div>
	</div>
</div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, ref } from 'vue'
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";
import WizzardDialogOverlay from "@/components/modal/WizzardDialogOverlay.vue";

const emit = defineEmits(['openWizzard'])

const wizzardType = WizzardType.GENERAL_OFFER;
const wizzardVisible = ref(false);
const openWizzard = () => {
  wizzardVisible.value = true;
  emit('openWizzard')
};
</script>

<style scoped>

</style>
