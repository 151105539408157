<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10" @close="cancelDialogOutsideClick()">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
							 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 backdrop-filter backdrop-blur-md transition-opacity"/>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300"
									 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
									 leave-from="opacity-100 translate-y-0 sm:scale-100"
									 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel
								class="relative transform overflow-hidden m-auto rounded-2xl bg-tc-bglightest px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-3xl sm:p-6">
							<div class="absolute right-0 top-0 pr-4 pt-4">
								<button type="button"
										class="rounded-xl text-gray-400 hover:text-gray-500 focus:outline-none"
										@click="cancelDialog()">
									<span class="sr-only">{{ t('close') }}</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
								</button>
							</div>
							<div class="sm:flex ">

                <div class="flex content-center p-2 " v-if="showLogo">
						<img loading="lazy" alt="Logo" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/logo.webp" class="self-center max-w-[3.5em] pr-2.5" >
						<p class="text-gray-500 text-left">theconcept technologies, LLC<br> <span class="text-gray-400"> together for the better</span>
						</p>
				</div>
								<div v-else class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<component :is="icon" class="h-6 w-6 text-red-600" aria-hidden="true"/>
								</div>
								<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
										{{ title }}
									</DialogTitle>
									<div class="mt-2">
										<p class="text-sm text-gray-500" v-html="description"></p>
									</div>
								</div>
							</div>
							<div class="mt-5 sm:mt-4">
								<WizzardComponent v-if="currentWizzard" :wizzard="currentWizzard"
												  @wizzard-finished="confirm" @wizard-reset="resetWizzard(true)"
												  @wizard-cancelled="cancelDialog"></WizzardComponent>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon, ExclamationCircleIcon} from '@heroicons/vue/24/outline';
import WizzardComponent from '@/components/inquiry/wizzard/WizzardComponent.vue';
import {WizzardType} from '@/theconcept/wizzard/base/wizzard.interface';
import useWizzardFactory from '@/composable/wizzard/useWizzardFactory';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'WizzardDialogOverlay',
	components: {
		WizzardComponent,
    XMarkIcon, ExclamationCircleIcon,
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: Object, default: ExclamationCircleIcon},
    showLogo: {type: Boolean, default: true},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		wizzardType: {
			type: String as () => WizzardType,
			default: WizzardType.WEB_DEVELOPMENT,
		},
		allowBodyDismiss: {
			type: Boolean,
			default: false,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm', 'close'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();
		const currentWizzard = ref();
		const {getWizzardFactory} = useWizzardFactory();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm');
		}

    function resetWizzard(closeDialog = false) {
      currentWizzard.value = getWizzardFactory().createWizzardByType(props.wizzardType);
      if (closeDialog) {
        cancelDialog();
      }
    }

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
      resetWizzard();
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
			currentWizzard,
      resetWizzard,
		};
	},
});
</script>

<style lang="scss">
</style>
