import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"padding-top":"8em"} }

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Messenger from "@/components/Messenger";
import CookiesDialog from "@/components/modal/CookiesDialog";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Navbar)),
    _createVNode(_unref(Messenger)),
    _createVNode(_component_router_view),
    _createVNode(_unref(CookiesDialog)),
    _createVNode(_unref(Footer))
  ]))
}
}

})