import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import SwiperMain from "@/components/animations/swiper/SwiperMain.vue";
import useSystem from "@/composable/core/useSystem";

// SwiperMain items

export default /*@__PURE__*/_defineComponent({
  __name: 'WorkWithSwiper',
  setup(__props) {

const swiperItems = [
  {src: '/img/anthropic.svg', alt: 'Anthropic Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/aws.svg', alt: 'AWS Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/digitalocean.svg', alt: 'DigitalOcean Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/google.svg', alt: 'Google Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/intelj.png', alt: 'Intel Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/microsoft.svg', alt: 'Microsoft Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/openai.svg', alt: 'OpenAI Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/udemy.svg', alt: 'Udemy Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/vuejs.svg', alt: 'Vue.js Logo', class: 'object-contain h-20 w-full grayscale'},
];

const {isMobileDevice} = useSystem();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SwiperMain, {
      loop: true,
      items: swiperItems,
      "allow-touch-move": false,
      "free-mode": false,
      "space-between": 12,
      speed: _unref(isMobileDevice)() ? 5000 : 10000
    }, _createSlots({ _: 2 }, [
      _renderList(swiperItems, (item, index) => {
        return {
          name: `item-${index}`,
          fn: _withCtx(() => [
            _createElementVNode("img", {
              loading: "lazy",
              src: item.src,
              alt: item.alt,
              class: _normalizeClass(["object-contain h-20 w-1/2 md:w-full grayscale", item.class])
            }, null, 10, _hoisted_1)
          ])
        }
      })
    ]), 1032, ["speed"])
  ]))
}
}

})