import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"overflow":"auto","position":"fixed","bottom":"14px","z-index":"100","width":"100%"}
}
const _hoisted_2 = {
  id: "cookiesComponent",
  class: "",
  "data-aos": "fade-up",
  "data-aos-anchor": "#cookiesComponent"
}
const _hoisted_3 = {
  id: "test1",
  class: "mx-auto max-w-4xl"
}
const _hoisted_4 = { class: "float-right mt-4 mr-12" }
const _hoisted_5 = { class: "bg-tc-bglight text-white p-6 mx-4 rounded-2xl" }
const _hoisted_6 = { class: "mt-3 text-center sm:mt-0 sm:ml-4" }
const _hoisted_7 = {
  id: "modal-headline",
  class: "text-xl leading-6 font-medium text-gray-300"
}
const _hoisted_8 = { class: "text-sm text-gray-300" }
const _hoisted_9 = { class: "mt-4 overflow-auto max-h-56" }
const _hoisted_10 = { class: "text-left text-sm text-gray-300 overflow-hidden" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "mb-5 break-all" }
const _hoisted_13 = { class: "font-medium text-md mb-2" }
const _hoisted_14 = { class: "text-md mb-2" }
const _hoisted_15 = { class: "font-medium text-md mb-2" }
const _hoisted_16 = { class: "font-medium text-md mb-2" }
const _hoisted_17 = { class: "font-medium text-md mb-2" }
const _hoisted_18 = { class: "font-medium text-md mb-2" }
const _hoisted_19 = { class: "font-medium text-md mb-2" }
const _hoisted_20 = { class: "font-medium text-md mb-2" }
const _hoisted_21 = { class: "font-medium text-md mb-2" }
const _hoisted_22 = { class: "font-medium text-md mb-2" }
const _hoisted_23 = { class: "font-medium text-md mb-2" }
const _hoisted_24 = { class: "font-medium text-md mb-2" }
const _hoisted_25 = { class: "font-medium text-md mb-2" }
const _hoisted_26 = { class: "font-medium text-md mb-2 break-all" }
const _hoisted_27 = { class: "font-medium text-md mb-2" }
const _hoisted_28 = { class: "font-medium text-md mb-2" }
const _hoisted_29 = { class: "font-medium text-md mb-2" }
const _hoisted_30 = {
  class: "list-disc list-inside text-md font-bold",
  style: {"list-style-type":"disc","list-style-position":"inside"}
}
const _hoisted_31 = { class: "font-medium" }
const _hoisted_32 = { class: "font-medium" }
const _hoisted_33 = { class: "font-medium" }
const _hoisted_34 = { class: "font-medium" }
const _hoisted_35 = { class: "font-medium text-md mb-2" }
const _hoisted_36 = { class: "font-medium text-md mb-2" }
const _hoisted_37 = { class: "font-medium text-md mb-2" }
const _hoisted_38 = { class: "font-medium text-md mb-2" }
const _hoisted_39 = { class: "font-medium text-md mb-2" }
const _hoisted_40 = { class: "font-medium text-md mb-2" }
const _hoisted_41 = { class: "font-medium text-md mb-2" }
const _hoisted_42 = { class: "font-medium text-md mb-2" }
const _hoisted_43 = { class: "font-medium text-md mb-2" }
const _hoisted_44 = { class: "font-medium text-md mb-2" }
const _hoisted_45 = { class: "font-medium text-md" }
const _hoisted_46 = { class: "font-medium text-md" }
const _hoisted_47 = { class: "font-medium text-md" }
const _hoisted_48 = { class: "font-medium text-md" }
const _hoisted_49 = { class: "font-medium text-md mb-2" }
const _hoisted_50 = { class: "font-medium text-md mb-2" }
const _hoisted_51 = { class: "font-medium text-md mb-2" }
const _hoisted_52 = { class: "text-sm text-gray-300 mb-2" }
const _hoisted_53 = { class: "mx-auto mb-12" }
const _hoisted_54 = { class: "mt-0 justify-center mx-auto px-4 pb-0 sm:px-6 sm:flex sm:flex-row-reverse" }
const _hoisted_55 = { class: "text-center" }
const _hoisted_56 = { class: "mt-4 mb-2" }
const _hoisted_57 = { class: "text-sm text-center mx-4 md:mx-16 lg:mx-20 text-gray-300" }
const _hoisted_58 = { class: "text-center" }
const _hoisted_59 = { class: "mt-4 mb-2" }
const _hoisted_60 = { class: "text-sm text-center mx-4 md:mx-16 lg:mx-20 text-gray-300" }
const _hoisted_61 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.hasCookiesAccepted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showButton === true)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDialog && _ctx.toggleDialog(...args)))
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_XMarkIcon, {
                "aria-hidden": "true",
                class: "w-4 absolute z-20 text-gray-400",
                onClick: _ctx.cancelDialog
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesText")), 1),
                (_ctx.showButton === true)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "mt-2 mb-0",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleInformation && _ctx.toggleInformation(...args)))
                    }, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesGuideline")), 1),
                      _withDirectives(_createElementVNode("div", _hoisted_9, [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesTitle")), 1),
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail1")), 1),
                              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail2")), 1),
                              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail3")), 1),
                              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail4")), 1),
                              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail5")), 1),
                              _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail6")), 1),
                              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesDetail7")), 1),
                              _createElementVNode("ol", null, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesTitle2")), 1),
                                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail1")), 1),
                                  _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail2")), 1),
                                  _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail3")), 1),
                                  _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail4")), 1),
                                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail5")), 1),
                                  _createElementVNode("p", _hoisted_25, [
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2TitelDetail6")), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2Detail6")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_26, [
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2TitelDetail7")), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2Detail7")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_27, [
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2TitelDetail8")), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2Detail8")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_28, [
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2TitelDetail9")), 1),
                                    _createTextVNode(" " + _toDisplayString(_ctx.t("modal.CookiesDialog..Cookies2Detail9")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2Detail10")), 1),
                                  _createElementVNode("ul", _hoisted_30, [
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2List1Item1")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2List1Item2")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies2List1Item3")), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3Title")), 1),
                                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3Detail")), 1),
                                  _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3Detail2")), 1),
                                  _createElementVNode("ol", null, [
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerTitle1")), 1),
                                      _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerDetail1")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerTitle2")), 1),
                                      _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerDetail2")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerTitle3")), 1),
                                      _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerDetail3")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerTitle4")), 1),
                                      _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerDetail4")), 1)
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerTitle5")), 1),
                                      _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.t("modal.CookiesDialog.Cookies3InnerDetail5")), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesTitle")), 1),
                                  _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail")), 1),
                                  _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail1")), 1),
                                  _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail2")), 1),
                                  _createElementVNode("p", _hoisted_45, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail3")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_46, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail4")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_47, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail5")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_48, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail6")), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_49, _toDisplayString(_ctx.t("modal.CookiesDialog.DeleteCookiesDetail7")), 1)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.t("modal.CookiesDialog.DataProtectionCookiesTitle")), 1),
                                  _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.t("modal.CookiesDialog.DataProtectionCookiesDetail")), 1),
                                  _createElementVNode("p", _hoisted_51, _toDisplayString(_ctx.t("modal.CookiesDialog.DataProtectionCookiesDetail2")), 1)
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("p", _hoisted_52, _toDisplayString(_ctx.t("modal.CookiesDialog.PrivacyInformation")), 1),
                        _createElementVNode("div", _hoisted_53, [
                          _createVNode(_component_router_link, { to: "/privacy-policy" }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createElementVNode("div", { class: "mt-2 btn bg-top px-10 text-gray-200 bg-tc-bglightest hover:bg-tc-bglighest" }, " Privacy Policcy ", -1)
                            ])),
                            _: 1
                          })
                        ])
                      ], 512), [
                        [_vShow, _ctx.informationVisible]
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.t("modal.CookiesDialog.textAllCookies")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "mt-2 btn bg-top px-10 text-gray-900 bg-tc-green hover:bg-tc-green",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm(true)))
                  }, "accept all ")
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.t("modal.CookiesDialog.textEssentialCookies")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", {
                      class: "mt-2 btn bg-top px-10 text-gray-900 bg-tc-blue hover:bg-tc-blue",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirm(false)))
                    }, "accept essentiell ")
                  ])
                ])
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.dialogVisible]
        ])
      ]))
    : _createCommentVNode("", true)
}