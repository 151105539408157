<template>
	<div class="wizard-container text-white divide-y divide-gray-200 bg-tc-bglight rounded-2xl overflow-hidden">
		<ConfettiBombAnimation v-if="showFinishedAnimation && wizzardObject && wizzardObject.isWizzardDone()" speed="0.7"
							   :sound-enabled="false"></ConfettiBombAnimation>
		<div v-if="wizzardObject && wizzardObject.isWizzardDone()">
      <div class="p-10">
        <h1 v-html="t('wizzard.base.success.title')"></h1>
        <p v-html="t('wizzard.base.success.description')"></p>
      </div>
      <button @click="closeWizzard"
              class="relative w-full p-10 bg-tc-blue border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10"
              type="button"> {{t('wizzard.base.success.button_close')}}
      </button>
		</div>
		<div v-else>
			<div :class="['justify-center rounded-t-2xl py-4 px-2 font-bold text-center ', wizzardColor]">{{ wizzardName }}</div>
			<div class="px-4 py-5 sm:px-6">
				<div class="block sm:hidden">
					<h4 class="sr-only">Status</h4>
					<p class="text-sm font-medium text-gray-900">
				  <span
						  class="inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
    <svg class="h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true">
      <circle cx="3" cy="3" r="3"/>
    </svg>
						<span> {{ currentStep }} / {{ stepCount }}</span><span
						  v-if="currentStepStatusInfo"> - {{ $t(currentStepStatusInfo.title) }}</span>
  </span>
					</p>
				</div>
				<div v-if="stepStatusInfo" class="hidden sm:block mt-6" aria-hidden="true">
					<div class="overflow-hidden rounded-full bg-gray-200">
						<transition name="progress-bar">
							<div :class="['h-2 rounded-full', wizzardColor]" :style="{ width: progressBarWidth }"/>
						</transition>
					</div>
					<div :class="['mt-6 text-sm font-medium text-gray-600 flex justify-between']">
						<div v-for="stepInfo in stepStatusInfo" :key="stepInfo.step"
							 :class="[stepInfo.step > 1 && stepInfo.step < stepCount ? 'text-center' : '', stepInfo.step === stepCount ? 'text-right' : '', currentStep === stepInfo.step ? wizzardPrimaryTextColor + ' font-bold ' : wizzardDefaultStepTextColor]">
							<a class="cursor-pointer no-underline" @click="goToStep(stepInfo.step)">{{ $t(stepInfo.title) }}</a>
						</div>
					</div>
				</div>
			</div>
			<div class="px-4 py-5 sm:p-6">
				<!-- Content goes here -->
				<transition name="fade" mode="out-in">
					<div class="wizard-content mb-4" :key="currentStep">
						<!--					TODO show loading screen inside -->
						<component key="step" v-if="isStepComponentLoaded && currentStepComponent"
								   :is="currentStepComponent" :wizzard="wizzardObject"/>
						<component key="summary" v-if="isSummaryComponentLoaded && summaryComponent" :is="summaryComponent"
								   :wizzard="wizzardObject"/>
					</div>
				</transition>
			</div>
			<div class="px-4 py-4 sm:px-6 wizard-actions flex justify-between">
				<button v-if="isPrevStepAllowed" @click="prevStep"
						:class="['bg-gray-500 text-white px-4 py-2 rounded']">
          {{t('wizzard.base.buttons.go_back')}}
				</button>
        <button v-else @click="closeWizzard" :disabled="(isPrevStepAllowed)"
                :class="['bg-gray-500 rounded-xl shadow-sm text-white px-4 py-2', isPrevStepAllowed ? 'disabled:opacity-40' : '']">
          {{t('wizzard.base.buttons.cancel')}}
        </button>
				<button v-if="!isLastStep && !isSummaryComponentLoaded" @click="nextStep" :disabled="(!isNextStepAllowed)"
						:class="['text-white px-4 py-2 rounded-xl shadow-sm', !isNextStepAllowed ? 'disabled:opacity-40' : 'font-semibold', wizzardColor]">
					{{t('wizzard.base.buttons.next_step')}}
				</button>
				<button v-else-if="isLastStep && !isSummaryComponentLoaded" @click="showSummary"
						:disabled="(!isNextStepEnabled)"
						:class="['text-white px-4 py-2 rounded-xl shadow-sm', !isNextStepEnabled ? 'disabled:opacity-40' : 'font-semibold', wizzardColor]">
          {{t('wizzard.base.summary.fields.confirm')}}
				</button>
				<button v-else-if="isLastStep && isSummaryComponentLoaded" @click="finishWizzard"
						:class="['text-white px-4 py-2 font-semibold rounded-xl shadow-sm', wizzardColor]">{{t('wizzard.base.summary.fields.button_request')}}
				</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, computed, defineComponent, defineAsyncComponent, onMounted} from 'vue';
import {AbstractWizzard} from '@/theconcept/wizzard/base/abstract_wizzard';
import {OfferService} from "@/theconcept/api/offer/offer.service";
import useTranslation from "@/composable/core/useTranslation";

export default defineComponent({
	name: 'WizzardComponent',
	components: {
    ConfettiBombAnimation: defineAsyncComponent(() => import('@/components/animations/Lottie/ConfettiBombAnimation/ConfettiBombAnimation.vue'))
  },
	props: {
		wizzard: {
			type: Object as () => AbstractWizzard,
			required: true,
		},
	},
	emits: ['wizzard-show-summary', 'wizzard-last-step', 'wizzard-finished', 'wizard-cancelled', 'wizard-reset'],
	setup(props, {emit}) {
    const {t} = useTranslation();
    const wizzardObject = ref<AbstractWizzard>(props.wizzard);
		const currentStepObject = ref();
		const showFinishedAnimation = ref(false);
		const isStepComponentLoaded = ref(false);
		const currentStepComponent = ref<any>(null);
		const isSummaryComponentLoaded = ref(false);
		const summaryComponent = ref<any>(null);


		const stepCount = computed(() => {
			return wizzardObject.value.getStepsCount();
		});

		const wizzardName = computed(() => {
			return wizzardObject.value.getWizzardName();
		});

		const wizzardColor = computed(() => {
			return wizzardObject.value.getWizzardColor();
		});

		const wizzardPrimaryTextColor = computed(() => {
			return wizzardObject.value.getWizzardPrimaryTextColor();
		});

		const wizzardDefaultStepTextColor = computed(() => {
			return wizzardObject.value.getWizzardDefaultStepTextColor();
		});

		const currentStep = computed(() => {
			return wizzardObject.value.getCurrentStep();
		});

		const isPrevStepAllowed = computed(() => {
			return wizzardObject.value.isPreviousStepAllowed() || isSummaryComponentLoaded.value;
		});

		const isNextStepAllowed = computed(() => {
			return wizzardObject.value.isNextStepAllowed();
		});

		const isNextStepEnabled = computed(() => {
			return wizzardObject.value.isNextStepEnabled;
		});

		const isLastStep = computed(() => {
			return wizzardObject.value.isLastStep();
		});

		const stepStatusInfo = computed(() => {
			return wizzardObject.value.getStepsStatusInfo();
		});

		const currentStepStatusInfo = computed(() => {
			return wizzardObject.value.getStepStatusInfo(currentStep.value);
		});

		const progressBarWidth = computed(() => {
			return wizzardObject.value.getStepProgressInPercent() + '%';
		});

		const goToStep = (step: number) => {
			wizzardObject.value.goToStep(step);
			loadComponent();
		};

		const loadComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			summaryComponent.value = null;
			const currentStepComponentName = wizzardObject.value.getCurrentStepComponent();
			if (!currentStepComponentName) {
				return;
			}

			let componentPath;
			if (currentStepComponentName.startsWith('base/')) {
				componentPath = `./${currentStepComponentName}.vue`;
			} else {
				componentPath = `./${wizzardObject.value.getStepsPath()}/${currentStepComponentName}.vue`;
			}

      // eslint-disable-next-line
      currentStepComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isStepComponentLoaded.value = true;
		};

		const loadSummaryComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			currentStepComponent.value = null;
			const wizzardSummaryComponent = wizzardObject.value.getWizzardSummaryComponent();
			if (!wizzardSummaryComponent) {
				return;
			}
			const componentPath = `./${wizzardObject.value.getWizzardSummaryBasePath()}/${wizzardSummaryComponent}.vue`;
      // eslint-disable-next-line
			summaryComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isSummaryComponentLoaded.value = true;
		};


    const sendRequest = () => {
      const data = wizzardObject.value.serializeForApiRequest();
      return OfferService.create(data).then(() => {
            return true
          },
          error => {
            console.log(error);
            return false
          });
    }

		const finishWizzard = () => {
      sendRequest().then((result) => {
        if (!result) {
          //TODO show error
          return;
        }
        wizzardObject.value.markWizzardAsDone();
        showFinishedAnimation.value = true;
        if (wizzardObject.value.autoCloseWizzardAfterLastStep) {
          setTimeout(() => {
            loadComponent();
            emit('wizzard-finished');
          }, 2000);
        } else {
          emit('wizzard-finished');
          setTimeout(() => {
            closeWizzard();
          }, 15000)
        }
      })
		};

		const showSummary = () => {
			wizzardObject.value.goToSummaryStep();
			emit('wizzard-last-step');
			loadSummaryComponent();
			emit('wizzard-show-summary');
		};

		const nextStep = () => {
			const nextStep = wizzardObject.value.goToNextStep();
			if (!nextStep) {
				emit('wizzard-last-step');
			} else {
				loadComponent();
			}
		};

    const closeWizzard = () => {
      emit('wizard-reset');
    }

    //TODO send request, check multi checkbox services, Summary fix

		const prevStep = () => {
			if (isSummaryComponentLoaded.value) {
				goToStep(currentStep.value);
			} else {
				const previousStep = wizzardObject.value.goToPreviousStep();
				if (!previousStep) {
					emit('wizard-cancelled');
				} else {
					loadComponent();
				}
			}
		};

		onMounted(() => {
			//TODO load step or component by path
			loadComponent();
		});

		return {
      t,
			stepCount,
			currentStep,
			currentStepObject,
			nextStep,
			prevStep,
			showSummary,
			wizzardColor,
			wizzardPrimaryTextColor,
      wizzardDefaultStepTextColor,
			wizzardName,
			isPrevStepAllowed,
			isNextStepAllowed,
			stepStatusInfo,
			currentStepStatusInfo,
			wizzardObject,
			currentStepComponent,
			isStepComponentLoaded,
			progressBarWidth,
			isLastStep,
			finishWizzard,
			isSummaryComponentLoaded,
			summaryComponent,
			goToStep,
			showFinishedAnimation,
			isNextStepEnabled,
      closeWizzard,
		};
	},
});
</script>

<style>
.progress-bar-enter-active, .progress-bar-leave-active {
    transition: width 0.5s ease;
}

/* Define initial and final states */
.progress-bar-enter, .progress-bar-leave-to {
    width: 0;
}
</style>
