<template>
  <div v-if="isActive" class="mr-5" :style="bounceStyle" style="position: fixed; right: 0; bottom: 0; width: 1%; z-index: 9;">
    <div class="mb-12 float-right">
      <!-- DESKTOP MENU -->
      <div class="">
        <div class="">
          <div class="flex h-full">
            <div class="hidden md:block">
              <div class="mx-4 flex justify-between h-full items-center">
                <router-link
                    alt="Get in touch with us"
                    title="Get in touch with us"
                    class="transition text-black sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn"
                    :class="{'animate-bounce temporary-bounce': bounceCount}"
                    to="/contact"
                >
                  <ChatBubbleBottomCenterTextIcon
                      class="h-6 w-6"
                      aria-hidden="true"
                      alt="Get in touch with us"
                      title="Get in touch with us"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MOBILE MENU -->
      <div id="mobile-menu" class="md:hidden">
        <div class="mx-4 flex justify-between h-full items-center">
          <router-link
              class="transition text-black sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn"
              :class="{'animate-bounce temporary-bounce': bounceCount}"
              to="/contact"
          >
            <ChatBubbleBottomCenterTextIcon class="h-6 w-6" aria-hidden="true"/>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref, watch } from 'vue'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/24/outline'
import {RouteChecker} from "@/theconcept/core/route_checker";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Messenger',
  components: {
    ChatBubbleBottomCenterTextIcon,
  },
  props: {
    bounceCount: {
      type: Number as PropType<number>,
      default: 4.5,
    },
    showAlways: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const bounceStyle = computed(() => ({
      '--bounce-count': props.bounceCount,
    }))


    const activeRoutes = ['/'];
    const routeChecker = new RouteChecker(activeRoutes);
    const route = useRoute();
    const isActive = ref<boolean>(routeChecker.isRouteActive(route.path)) || props.showAlways;

    watch(
        () => route.path,
        (newPath) => {
          if (props.showAlways) {
            isActive.value = true;
            return;
          }
          isActive.value = routeChecker.isRouteActive(newPath);
        }
    );

    return {
      bounceStyle,
      isActive,
    }
  },
})
</script>

<style scoped>
.temporary-bounce {
  -webkit-animation-iteration-count: var(--bounce-count, 3);
  animation-iteration-count: var(--bounce-count, 3);
}
</style>