import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto py-12 pb-32 px-6 max-w-5xl sm:px-6 lg:px-8 lg:py-24" }
const _hoisted_2 = { class: "rounded-3xl overflow-hidden" }
const _hoisted_3 = { class: "max-w-[100em] px-7 py-8 lg:p-12 justify-self-center shadow-xl bg-tc-bglightest" }
const _hoisted_4 = { class: "items-center lg:max-w-none lg:flex" }
const _hoisted_5 = { class: "flex-1 bg-tc-bglightest pr-6 lg:pr-12" }
const _hoisted_6 = {
  id: "Title3WebDevelopment",
  class: "text-4xl mb-6 tracking-tight font-extrabold text-gray-300 sm:text-3xl xl:text-5xl"
}
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "flex-shrink-0 py-0.5 px-4 bg-tc-bglight text-xs sm:text-sm tracking-wider font-semibold uppercase text-gray-200" }
const _hoisted_10 = { class: "mt-4 text-md text-white" }
const _hoisted_11 = { class: "mt-2 text-md text-white" }

import { ref } from 'vue'
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";
import WizzardDialogOverlay from "@/components/modal/WizzardDialogOverlay.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralServiceOpenWizzard',
  emits: ['openWizzard'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const wizzardType = WizzardType.GENERAL_OFFER;
const wizzardVisible = ref(false);
const openWizzard = () => {
  wizzardVisible.value = true;
  emit('openWizzard')
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(WizzardDialogOverlay, {
      onCancelConfirm: _cache[0] || (_cache[0] = ($event: any) => (wizzardVisible.value = false)),
      "wizzard-type": _unref(wizzardType),
      visible: wizzardVisible.value
    }, null, 8, ["wizzard-type", "visible"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("figure", { class: "relative max-h-48 overflow-hidden" }, [
        _createElementVNode("img", {
          class: "w-full",
          src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/contact-banner.webp",
          alt: "Banner",
          loading: "lazy"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('services.request.title')), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('solutions.SolutionServiceDetail')), 1),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1 border-t-2 border-tc-bglight" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('solutions.SolutionService')), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('services.request.description')), 1)
          ]),
          _createElementVNode("button", {
            onClick: openWizzard,
            class: "btn cursor-pointer mt-6 lg:mt-0 no-underline flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold rounded-3xl text-white bg-tc-blue hover:bg-gray-500"
          }, _toDisplayString(_ctx.$t('webDevelopment.ServiceBtn')), 1)
        ])
      ])
    ])
  ]))
}
}

})