import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['mx-auto max-w-7xl py-16 sm:py-24'])
}
const _hoisted_2 = { class: "relative overflow-hidden rounded-3xl" }
const _hoisted_3 = { class: "absolute inset-0" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "relative mx-auto flex max-w-3xl flex-col items-center text-center" }
const _hoisted_6 = { class: "text-2xl font-bold tracking-tight text-white sm:text-4xl" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]


import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'PromotionCard',
  props: {
  title: {
    type: String,
    default: 'Get Promotions up to 4000€',
  },
  description: {
    type: String,
    default: 'Make your desk beautiful and organized. Post a picture to social media and watch it get more likes than life-changing announcements. Reflect on the shallow nature of existence. At least you have a really nice desk setup.',
  },
  bgColor: {
    type: String,
    default: 'bg-transparent',
  },
  bgImage: {
    type: String,
    default: 'https://tailwindui.com/img/ecommerce-images/home-page-01-feature-section-01.jpg',
  },
  showRedirectButton: {
    type: Boolean,
    default: true,
  },
  redirectText: {
    type: String,
    default: 'Show Promotions',
  },
  redirectLink: {
    type: String,
    default: '',
  },
  paddings: {
    type: String,
    default: 'px-4 py-16 sm:px-6 sm:py-24 lg:px-8',
  },
},
  setup(__props) {

const props = __props;

function redirect() {
  if (!props.redirectLink.length) {
    return;
  }

  const isExternal = /^(http|https):\/\//.test(props.redirectLink) &&
      !props.redirectLink.includes(window.location.hostname);

  if (isExternal) {
    window.open(props.redirectLink, '_blank');
  } else {
    router.push(props.redirectLink);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(__props.bgColor)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: __props.bgImage,
            loading: "lazy",
            alt: "promotion",
            class: "h-full w-full object-cover object-center"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(['relative bg-gray-900 bg-opacity-75', __props.paddings])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, [
              _createElementVNode("span", {
                class: "block sm:inline",
                innerHTML: __props.title
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("p", {
              class: "mt-3 text-xl text-white",
              innerHTML: __props.description
            }, null, 8, _hoisted_8),
            (__props.showRedirectButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: redirect,
                  class: "cursor-pointer mt-8 block w-full rounded-3xl border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-200 sm:w-auto",
                  innerHTML: __props.redirectText
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ])
    ])
  ], 2))
}
}

})