<template>
  <!--	TODO get country flag by code-->
  <div class="bp-font rounded-3xl">
    <select v-model="locale" :class="cssClass" @change="changeLanguage" aria-label="Choose your language">
      <option v-for="(languageName, languageCode) in arrLanguageMap" :key="languageCode" :value="languageCode">
        {{ languageName }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";
import useCustomStore from "@/composable/custom/useCustomStore";

export default defineComponent({
	name: 'LanguageSelector',
	components: {},
	props: {
		cssClass: {
			type: String,
			default: "btn btn-ghost btn-sm rounded-btn px-0 text-gray-500 pt-1 pb-1",
		},
		showOnlyKeys: {
			type: Boolean,
			default: false,
		}
	},
	setup(props) {
		const {setLanguageLocale} = useCustomStore();

		const arrLanguageMap = ref({"de": "Deutsch", "en": "English"});
		// const arrLanguageMap = ref({"de": "Deutsch", "en": "English", "es": "Espaniol", "fr": "France"});
		// const arrLanguages = ref(process.env.VUE_APP_I18N_LOCALE_LIST.split(','));
		const  {t, locale } = useTranslation();

		function changeLanguage() {
			setLanguageLocale((locale.value));
		}

		function loadLanguageMap() {
			if (props.showOnlyKeys) {
        arrLanguageMap.value = {"de": "de", "en": "en"};
        // arrLanguageMap.value = {"de": "de", "en": "en", "es": "es", "fr": "fr"};
			} else {
        arrLanguageMap.value = {"de": "Deutsch", "en": "English"};
        // arrLanguageMap.value = {"de": "Deutsch", "en": "English", "es": "Español", "fr": "French"};
			}
		}

		onMounted(() => {
			loadLanguageMap();
		})

		return {arrLanguageMap, t, locale, changeLanguage};
	}
})
</script>

<style scoped>

</style>
