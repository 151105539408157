import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" }
const _hoisted_3 = { class: "absolute right-0 top-0 pr-4 pt-4" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "sm:flex" }
const _hoisted_6 = {
  key: 0,
  class: "flex content-center p-2"
}
const _hoisted_7 = {
  key: 1,
  class: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
}
const _hoisted_8 = { class: "mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left" }
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "mt-5 sm:mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_WizzardComponent = _resolveComponent("WizzardComponent")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "relative z-10",
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancelDialogOutsideClick()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "fixed inset-0 backdrop-filter backdrop-blur-md transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "relative transform overflow-hidden m-auto rounded-2xl bg-tc-bglightest px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-3xl sm:p-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "rounded-xl text-gray-400 hover:text-gray-500 focus:outline-none",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDialog()))
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('close')), 1),
                          _createVNode(_component_XMarkIcon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        (_ctx.showLogo)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[4] || (_cache[4] = [
                              _createElementVNode("img", {
                                loading: "lazy",
                                alt: "Logo",
                                src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/logo.webp",
                                class: "self-center max-w-[3.5em] pr-2.5"
                              }, null, -1),
                              _createElementVNode("p", { class: "text-gray-500 text-left" }, [
                                _createTextVNode("theconcept technologies, LLC"),
                                _createElementVNode("br"),
                                _createTextVNode(),
                                _createElementVNode("span", { class: "text-gray-400" }, " together for the better")
                              ], -1)
                            ])))
                          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
                                class: "h-6 w-6 text-red-600",
                                "aria-hidden": "true"
                              }))
                            ])),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_DialogTitle, {
                            as: "h3",
                            class: "text-base font-semibold leading-6 text-gray-900"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("p", {
                              class: "text-sm text-gray-500",
                              innerHTML: _ctx.description
                            }, null, 8, _hoisted_10)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.currentWizzard)
                          ? (_openBlock(), _createBlock(_component_WizzardComponent, {
                              key: 0,
                              wizzard: _ctx.currentWizzard,
                              onWizzardFinished: _ctx.confirm,
                              onWizardReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetWizzard(true))),
                              onWizardCancelled: _ctx.cancelDialog
                            }, null, 8, ["wizzard", "onWizzardFinished", "onWizardCancelled"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}