import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "wizard-container text-white divide-y divide-gray-200 bg-tc-bglight rounded-2xl overflow-hidden" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "p-10" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "px-4 py-5 sm:px-6" }
const _hoisted_8 = { class: "block sm:hidden" }
const _hoisted_9 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_10 = { class: "inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "hidden sm:block mt-6",
  "aria-hidden": "true"
}
const _hoisted_13 = { class: "overflow-hidden rounded-full bg-gray-200" }
const _hoisted_14 = {
  class: /*@__PURE__*/_normalizeClass(['mt-6 text-sm font-medium text-gray-600 flex justify-between'])
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_17 = { class: "px-4 py-4 sm:px-6 wizard-actions flex justify-between" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfettiBombAnimation = _resolveComponent("ConfettiBombAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showFinishedAnimation && _ctx.wizzardObject && _ctx.wizzardObject.isWizzardDone())
      ? (_openBlock(), _createBlock(_component_ConfettiBombAnimation, {
          key: 0,
          speed: "0.7",
          "sound-enabled": false
        }))
      : _createCommentVNode("", true),
    (_ctx.wizzardObject && _ctx.wizzardObject.isWizzardDone())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", {
              innerHTML: _ctx.t('wizzard.base.success.title')
            }, null, 8, _hoisted_4),
            _createElementVNode("p", {
              innerHTML: _ctx.t('wizzard.base.success.description')
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeWizzard && _ctx.closeWizzard(...args))),
            class: "relative w-full p-10 bg-tc-blue border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10",
            type: "button"
          }, _toDisplayString(_ctx.t('wizzard.base.success.button_close')), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(['justify-center rounded-t-2xl py-4 px-2 font-bold text-center ', _ctx.wizzardColor])
          }, _toDisplayString(_ctx.wizzardName), 3),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "sr-only" }, "Status", -1)),
              _createElementVNode("p", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _cache[6] || (_cache[6] = _createElementVNode("svg", {
                    class: "h-1.5 w-1.5 fill-yellow-500",
                    viewBox: "0 0 6 6",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("circle", {
                      cx: "3",
                      cy: "3",
                      r: "3"
                    })
                  ], -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.currentStep) + " / " + _toDisplayString(_ctx.stepCount), 1),
                  (_ctx.currentStepStatusInfo)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, " - " + _toDisplayString(_ctx.$t(_ctx.currentStepStatusInfo.title)), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (_ctx.stepStatusInfo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_Transition, { name: "progress-bar" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(['h-2 rounded-full', _ctx.wizzardColor]),
                          style: _normalizeStyle({ width: _ctx.progressBarWidth })
                        }, null, 6)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepStatusInfo, (stepInfo) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: stepInfo.step,
                        class: _normalizeClass([stepInfo.step > 1 && stepInfo.step < _ctx.stepCount ? 'text-center' : '', stepInfo.step === _ctx.stepCount ? 'text-right' : '', _ctx.currentStep === stepInfo.step ? _ctx.wizzardPrimaryTextColor + ' font-bold ' : _ctx.wizzardDefaultStepTextColor])
                      }, [
                        _createElementVNode("a", {
                          class: "cursor-pointer no-underline",
                          onClick: ($event: any) => (_ctx.goToStep(stepInfo.step))
                        }, _toDisplayString(_ctx.$t(stepInfo.title)), 9, _hoisted_15)
                      ], 2))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock("div", {
                  class: "wizard-content mb-4",
                  key: _ctx.currentStep
                }, [
                  (_ctx.isStepComponentLoaded && _ctx.currentStepComponent)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStepComponent), {
                        key: "step",
                        wizzard: _ctx.wizzardObject
                      }, null, 8, ["wizzard"]))
                    : _createCommentVNode("", true),
                  (_ctx.isSummaryComponentLoaded && _ctx.summaryComponent)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.summaryComponent), {
                        key: "summary",
                        wizzard: _ctx.wizzardObject
                      }, null, 8, ["wizzard"]))
                    : _createCommentVNode("", true)
                ]))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_ctx.isPrevStepAllowed)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args))),
                  class: _normalizeClass(['bg-gray-500 text-white px-4 py-2 rounded'])
                }, _toDisplayString(_ctx.t('wizzard.base.buttons.go_back')), 1))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeWizzard && _ctx.closeWizzard(...args))),
                  disabled: (_ctx.isPrevStepAllowed),
                  class: _normalizeClass(['bg-gray-500 rounded-xl shadow-sm text-white px-4 py-2', _ctx.isPrevStepAllowed ? 'disabled:opacity-40' : ''])
                }, _toDisplayString(_ctx.t('wizzard.base.buttons.cancel')), 11, _hoisted_18)),
            (!_ctx.isLastStep && !_ctx.isSummaryComponentLoaded)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args))),
                  disabled: (!_ctx.isNextStepAllowed),
                  class: _normalizeClass(['text-white px-4 py-2 rounded-xl shadow-sm', !_ctx.isNextStepAllowed ? 'disabled:opacity-40' : 'font-semibold', _ctx.wizzardColor])
                }, _toDisplayString(_ctx.t('wizzard.base.buttons.next_step')), 11, _hoisted_19))
              : (_ctx.isLastStep && !_ctx.isSummaryComponentLoaded)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showSummary && _ctx.showSummary(...args))),
                    disabled: (!_ctx.isNextStepEnabled),
                    class: _normalizeClass(['text-white px-4 py-2 rounded-xl shadow-sm', !_ctx.isNextStepEnabled ? 'disabled:opacity-40' : 'font-semibold', _ctx.wizzardColor])
                  }, _toDisplayString(_ctx.t('wizzard.base.summary.fields.confirm')), 11, _hoisted_20))
                : (_ctx.isLastStep && _ctx.isSummaryComponentLoaded)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 4,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.finishWizzard && _ctx.finishWizzard(...args))),
                      class: _normalizeClass(['text-white px-4 py-2 font-semibold rounded-xl shadow-sm', _ctx.wizzardColor])
                    }, _toDisplayString(_ctx.t('wizzard.base.summary.fields.button_request')), 3))
                  : _createCommentVNode("", true)
          ])
        ]))
  ]))
}