<template>
  <div
      :class="['flex-wrap fixed navbar content-center font-extrabold rounded-3xl m-4 mt-1 shadow-lg backdrop-filter backdrop-blur-lg text-white top-3 z-10 transition-colors duration-300 ease-in-out', getHighlightColor]"
      style="width: -webkit-fill-available;"
  >
    <!--		 ${navbarColor}-->
    <div class="flex-1 w-1 px-2 mx-2">
      <span class="w-8 sm:w-8 sm:h-8 mr-4">
        <img loading="lazy" alt="theconcept Logo" src="../assets/logo.webp"/>
      </span>
      <router-link to="/" @click="close()">
        <span class="text-md sm:text-2xl font-bold text-left">
          {{ CompanyName }} {{ CompanySureName }}
        </span>
      </router-link>
    </div>

    <!-- Desktop -->
    <div class="flex-none hidden px-2 mx-2 lg:flex">
      <div class="flex items-stretch">
        <router-link
            class="btn btn-ghost btn-sm rounded-btn"
            to="/solutions"
        >
          <HeartIcon
              class="inline-block w-5 mb-1 mr-0 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.SolutionBtn") }}
        </router-link>
        <router-link class="btn btn-ghost btn-sm rounded-btn" to="/vision">
          <LightBulbIcon
              class="inline-block w-5 mr-0 mb-1 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.VisionBtn") }}
        </router-link>
        <router-link class="btn btn-ghost btn-sm rounded-btn" to="/company">
          <BuildingOfficeIcon
              class="inline-block w-4 mb-1 mr-0 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.CompanyBtn") }}
        </router-link>
        <router-link
            class="btn btn-ghost bg-tc-green text-white btn-sm mx-4 mr-0 px-6 rounded-btn"
            to="/contact"
        >
          {{ t("navbar.ContactBtn") }}
        </router-link>
      </div>
      <div class="flex ml-3 mb-1 rounded-3xl">
        <LanguageSelector
            css-class="btn btn-ghost btn-sm rounded-btn px-0 pt-1 pb-1"
            :show-only-keys="true"
        ></LanguageSelector>
      </div>
    </div>

    <!-- Mobile -->
    <div class="visible lg:hidden">
      <div class="flex -mr-1 sm:mr-1 rounded-3xl">
        <LanguageSelector
            css-class="btn btn-ghost btn-sm rounded-btn px-0 pt-1 pb-1"
            :show-only-keys="true"
        ></LanguageSelector>
      </div>
      <button
          v-show="!showMenu"
          aria-label="Open Menu"
          class="btn btn-square btn-ghost"
          @click="open"
      >
        <Bars3Icon
            class="inline-block w-6 h-6 stroke-current"
            aria-hidden="true"
        />
      </button>
      <button
          v-show="showMenu"
          aria-label="Close Menu"
          class="btn btn-square btn-ghost"
          @click="close"
      >
        <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
      </button>
      <br/>
    </div>

    <div
        class="justify-center"
        style="flex-basis: 100%; align-items: unset;"
    >
      <div
          class="grid grid-cols-1 my-36"
          :class="{'hidden': !showMenu, '': showMenu}"
      >
        <router-link
            class="btn btn-ghost rounded-btn col-1 m-4 mb-4 px-12"
            to="/solutions"
            @click="close()"
        >
          <HeartIcon
              class="inline-block w-5 mb-1 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.SolutionBtn") }}
        </router-link>
        <router-link
            class="btn btn-ghost border border-white rounded-btn col-1 m-4"
            to="/vision"
            @click="close()"
        >
          <light-bulb-icon
              class="inline-block w-5 mb-1 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.VisionBtn") }}
        </router-link>
        <router-link
            class="btn btn-ghost border border-white rounded-btn col-1 m-4"
            to="/company"
            @click="close()"
        >
          <building-office-icon
              class="inline-block w-5 mb-1 stroke-current"
              aria-hidden="true"
          />
          {{ t("navbar.CompanyBtn") }}
        </router-link>
        <router-link
            class="btn btn-ghost bg-tc-tcBtn border-white mx-4 mr-0 px-6 rounded-btn col-1 m-4"
            to="/contact"
            @click="close()"
        >
          {{ t("navbar.ContactBtn") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, onUnmounted} from 'vue';
import LanguageSelector from '@/components/Locale/LanguageSelector.vue';
import {
  HeartIcon,
  LightBulbIcon,
  BuildingOfficeIcon,
  XMarkIcon,
  Bars3Icon,
} from '@heroicons/vue/24/outline';
import useTranslation from '@/composable/translation/useTranslation';
import useCustomStore from "@/composable/custom/useCustomStore";

export default defineComponent({
  name: 'Navbar',
  components: {
    LanguageSelector,
    HeartIcon,
    LightBulbIcon,
    BuildingOfficeIcon,
    Bars3Icon,
    XMarkIcon,
  },
  props: {
    CompanyName: {
      type: String,
      default: 'theconcept',
    },
    CompanySureName: {
      type: String,
      default: 'technologies',
    },
  },
  setup() {
    const {t} = useTranslation();
    const showMenu = ref(false);
    const {getHighlightColor, setHighlightColor} = useCustomStore();

    const handleScroll = () => {
      // Update navbarColor based on global highlightColor
      if (window.scrollY === 0) {
        // setIsScrolled(false);
        setHighlightColor('bg-transparent');
      } else {
        // setIsScrolled(true);
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initialize on load
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    function open() {
      showMenu.value = true;
    }

    function close() {
      showMenu.value = false;
    }

    return {
      showMenu,
      open,
      close,
      t,
      getHighlightColor,
    };
  },
});
</script>
