<template>
	<!--  position before: -->
<!--  TODO change Footer! to show every menu point clear on Ipad!-->
	<footer class="block mt-20 overflow-hidden w-screen footer text-gray-400 sticky">
		<div><SolutionsCarousel main-css="w-screen mb-24" swiper-slide-css="mx-4" :header-text-active="true" :header-text="$t('services.choose_product')" :small-images="true"></SolutionsCarousel></div>
<div class="footer gap-4 p-10 bg-tc-bglight ">
		<div class="flex">
			<img loading="lazy" alt="Logo" height="30" src="../assets/logo.webp" style="align-self: center;" width="30">
			<p class="text-gray-500 text-left">theconcept technologies, LLC<br> <span class="text-gray-400"> together for the better
    </span>
			</p>
		</div>
	<div class="my-4 md:my-0">
			<span class="rounded-3xl"> <LanguageSelector></LanguageSelector></span>
			<div class="flex mt-2 gap-4">
				<a class="link link-hover" href="https://www.facebook.com/theconcept.technologies.llc">
					<img loading="lazy" class="text-gray-500" src="/img/socialMedia/facebook.svg" alt="theconcept technologies social facebook">
				</a>
				<a class="link link-hover" href="https://www.instagram.com/theconcept_technologies/">
					<img loading="lazy" src="/img/socialMedia/instagram.svg" alt="theconcept technologies social instagram">
				</a>
				<a class="link link-hover" href="https://www.linkedin.com/company/theconcept-technologies-llc/">
					<img loading="lazy" src="/img/socialMedia/linkedIn.svg" alt="theconcept technologies social LinkedIn">
				</a>
				<a class="link link-hover" href="https://www.reddit.com/r/theconcepttechnologie/">
					<img src="/img/socialMedia/reddit.svg" alt="theconcept technologies social reddit">
				</a>
<!--				      <a class="link link-hover" href="https://www.youtube.com">-->
<!--				        <img src="/img/socialMedia/youtube.svg">-->
<!--				      </a>-->

			</div>
    <div v-show="isFullCookieConsentAllowed" class="pt-4">
      <div class="trustpilot-widget" style="border-radius: 10px; padding: 10px" data-locale="en-US"
           data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="63459310865346305f41efa6"
           data-style-height="25px" data-style-width="100%" data-theme="light" data-min-review-count="0"
           data-style-alignment="center">
        <a href="https://www.trustpilot.com/review/theconcept-technologies.com" class="text-white" target="_blank" rel="noopener">rate
          or view us on Trustpilot</a>
      </div>
    </div>
    <div class="flex pt-2">
      <a href="https://www.wko.at/stmk" class="link link-hover" target="_blank" rel="noopener">
        <img loading="lazy" alt="Logo" class="grayscale hover:grayscale-0" height="30" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/wko_logo.png" style="align-self: center;" width="100">
      </a>
    </div>

			<!--			TODO other Solutions if needed -->
			<!-- TrustBox widget - Micro Review Count -->
<!--			<div v-show="isFullCookieConsentAllowed"  class="trustpilot-widget mt-4" data-locale="de-DE" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="63459310865346305f41efa6" data-style-height="44px" data-style-width="100%" data-theme="dark"  data-min-review-count="0" data-style-alignment="center">-->
<!--				<a href="https://de.trustpilot.com/review/theconcept-technologies.com" target="_blank" rel="noopener">Trustpilot</a>-->
<!--			</div>-->
			<!-- End TrustBox widget -->
			<!-- TrustBox widget - Review Collector -->
<!--			<div v-show="isFullCookieConsentAllowed"  class="trustpilot-widget mt-4 -ml-14"  data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="63459310865346305f41efa6" data-style-height="52px"  data-style-width="100%">-->
<!--				<a href="https://de.trustpilot.com/review/theconcept-technologies.com" target="_blank" rel="noopener">Trustpilot</a>-->
<!--			</div>-->
			<!-- End TrustBox widget -->
		</div>
		<div class="mb-4 md:mb-0">
			<span class="footer-title">{{ t('footer.service') }}</span>
			<router-link class="link link-hover" to="/ai-integration">{{ t('aiIntegration.Title') }}</router-link>
			<router-link class="link link-hover" to="/web-development">{{ t('webDevelopment.Title') }}</router-link>
			<router-link class="link link-hover" to="/funding">{{ t('navbar.FundingBtn') }}</router-link>
			<router-link class="link link-hover" to="/ar-vr-development">{{ t('arVrDevelopment.Title') }}</router-link>
			<router-link class="link link-hover" to="/api">{{ t('api.Title') }}</router-link>
			<router-link class="link link-hover" to="/product-development">{{ t('productDevelopment.Title') }}</router-link>
			<!--			<router-link class="link link-hover" to="/3d-printing">{{ t('3dPrinting.Title') }}</router-link>-->
			<router-link class="link link-hover" to="/branding">{{ t('branding.Title') }}</router-link>

		</div>
	<div class="mb-4 md:mb-0">
			<span class="footer-title">{{ t('footer.company') }}</span>
			<router-link class="link link-hover" to="/company">{{ t('footer.aboutUs') }}</router-link>
			<router-link class="link link-hover" to="/investor-area">{{ t('navbar.InvestmentBtn') }}</router-link>
<!--			<router-link class="link link-hover" to="/certificates">{{ t('navbar.Certification') }}</router-link>-->

		<!--      <router-link class="link link-hover" style="background: red;" to="/blog">Blog</router-link>-->
			<router-link class="link link-hover" to="/contact">{{ t('footer.contact') }}</router-link>
			<router-link class="link link-hover" to="/press-kit">{{ t('footer.press') }}</router-link>
		<router-link class="link link-hover" to="/jobs">{{ t('footer.jobs') }}</router-link>

	</div>
		<div>
			<span class="footer-title">{{ t('footer.legal') }}</span>
			<router-link class="link link-hover" to="/terms-of-use">{{ t('footer.terms') }}</router-link>
			<router-link class="link link-hover" to="/privacy-policy">{{ t('footer.privacy') }}</router-link>
			<router-link class="link link-hover" to="/imprint">{{ t('footer.imprint') }}</router-link>
			<router-link class="link link-hover" to="/cookie-policy">{{ t('footer.cookies') }}</router-link>
		</div>
</div>
	</footer>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import LanguageSelector from "@/components/Locale/LanguageSelector.vue";
import useTranslation from "@/composable/translation/useTranslation";
import useCustomStore from "@/composable/custom/useCustomStore";
import SolutionsCarousel from "@/components/services/SolutionsCarousel.vue";

const {t} = useTranslation();
const copyRightText = ref('');
const {isFullCookieConsentAllowed} = useCustomStore();

function getCopyrightText() {
  const currentYear = new Date().getFullYear();
  copyRightText.value = "©" + " " + currentYear + " by " + process.env.VUE_APP_NAME;
}

onMounted(() => {
  getCopyrightText();
})

</script>

<style scoped>
</style>
