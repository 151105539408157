import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "carousel-mask" }

import {computed} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import {Navigation, Pagination, Autoplay, Mousewheel, FreeMode} from 'swiper/modules';


export default /*@__PURE__*/_defineComponent({
  __name: 'SwiperMain',
  props: {
  items: {
    type: Array,
    required: true
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  imageStyle: {
    type: String,
    default: ''
  },
  spaceBetween: {
    type: Number,
    default: 5
  },
  defaultImage: {
    type: String,
    default: ''
  },
  loop: {
    type: Boolean,
    default: false
  },
  speed: {
    type: Number,
    default: 10000
  },
  slidesPerView: {
    type: [String, Number],
    default: 'auto'
  },
  freeMode: {
    type: Boolean,
    default: true
  },
  allowTouchMove: {
    type: Boolean,
    default: true
  },
  reverseDirection: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props;

const swiperModules = [Navigation, Pagination, Autoplay, Mousewheel, FreeMode];
const mousewheel = {releaseOnEdges: true};
const pagination = {
  clickable: true,
  el: '.swiper-pagination',
};

const autoplayConfig = computed(() => ({
  delay: 0,
  disableOnInteraction: false,
  reverseDirection: props.reverseDirection,
  pauseOnMouseEnter: false,
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(__props.wrapperClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Swiper), {
        centeredSlides: false,
        stopOnLastSlide: false,
        waitForTransition: true,
        modules: swiperModules,
        direction: "horizontal",
        mousewheel: mousewheel,
        spaceBetween: __props.spaceBetween,
        loop: __props.loop,
        autoplay: autoplayConfig.value,
        speed: __props.speed,
        pagination: pagination,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
        slidesPerView: __props.slidesPerView,
        freeMode: __props.freeMode,
        allowTouchMove: __props.allowTouchMove
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
            return (_openBlock(), _createBlock(_unref(SwiperSlide), {
              key: index,
              class: "flex"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, `item-${index}`, { item: item })
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 3
      }, 8, ["spaceBetween", "loop", "autoplay", "speed", "slidesPerView", "freeMode", "allowTouchMove"])
    ])
  ], 2))
}
}

})