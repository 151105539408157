<template>
  <div :class="wrapperClass">
    <div class="carousel-mask">
      <Swiper
          :centeredSlides="false"
          :stopOnLastSlide="false"
          :waitForTransition="true"
          :modules="swiperModules"
          direction="horizontal"
          :mousewheel="mousewheel"
          :spaceBetween="spaceBetween"
          :loop="loop"
          :autoplay="autoplayConfig"
          :speed="speed"
          :pagination="pagination"
          :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
          :slidesPerView="slidesPerView"
          :freeMode="freeMode"
          :allowTouchMove="allowTouchMove"
      >
        <SwiperSlide
            v-for="(item, index) in items"
            :key="index" class="flex">
          <slot :name="`item-${index}`" :item="item">
          </slot>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import {Navigation, Pagination, Autoplay, Mousewheel, FreeMode} from 'swiper/modules';

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  imageStyle: {
    type: String,
    default: ''
  },
  spaceBetween: {
    type: Number,
    default: 5
  },
  defaultImage: {
    type: String,
    default: ''
  },
  loop: {
    type: Boolean,
    default: false
  },
  speed: {
    type: Number,
    default: 10000
  },
  slidesPerView: {
    type: [String, Number],
    default: 'auto'
  },
  freeMode: {
    type: Boolean,
    default: true
  },
  allowTouchMove: {
    type: Boolean,
    default: true
  },
  reverseDirection: {
    type: Boolean,
    default: false
  }
});

const swiperModules = [Navigation, Pagination, Autoplay, Mousewheel, FreeMode];
const mousewheel = {releaseOnEdges: true};
const pagination = {
  clickable: true,
  el: '.swiper-pagination',
};

const autoplayConfig = computed(() => ({
  delay: 0,
  disableOnInteraction: false,
  reverseDirection: props.reverseDirection,
  pauseOnMouseEnter: false,
}));

</script>

<style scoped>


.swiper {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.swiper-slide {
  width: auto;
  max-width: 23em;
  height: auto;
}

@media (min-width: 640px) {
  .swiper-slide {
    width: calc(50% - 15px);
  }
}

@media (min-width: 1024px) {
  .swiper-slide {
    width: calc(33.333% - 20px);
  }
}

.carousel-mask {
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(to left,
  transparent,
  black 15%,
  black 85%,
  transparent
  );
  -webkit-mask-image: linear-gradient(to left,
  transparent,
  black 15%,
  black 85%,
  transparent
  );
}
</style>
