<template>
  <div :class="bgColor">
    <div :class="['mx-auto max-w-7xl py-16 sm:py-24']">
      <div class="relative overflow-hidden rounded-3xl">
        <div class="absolute inset-0">
          <img :src="bgImage" loading="lazy" alt="promotion" class="h-full w-full object-cover object-center" />
        </div>
        <div :class="['relative bg-gray-900 bg-opacity-75', paddings]">
          <div class="relative mx-auto flex max-w-3xl flex-col items-center text-center">
            <h2 class="text-2xl font-bold tracking-tight text-white sm:text-4xl">
              <span class="block sm:inline" v-html="title"/>
            </h2>
            <p class="mt-3 text-xl text-white" v-html="description"/>
            <button v-if="showRedirectButton" @click="redirect" class="cursor-pointer mt-8 block w-full rounded-3xl border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-200 sm:w-auto" v-html="redirectText"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import router from "@/router";

const props = defineProps({
  title: {
    type: String,
    default: 'Get Promotions up to 4000€',
  },
  description: {
    type: String,
    default: 'Make your desk beautiful and organized. Post a picture to social media and watch it get more likes than life-changing announcements. Reflect on the shallow nature of existence. At least you have a really nice desk setup.',
  },
  bgColor: {
    type: String,
    default: 'bg-transparent',
  },
  bgImage: {
    type: String,
    default: 'https://tailwindui.com/img/ecommerce-images/home-page-01-feature-section-01.jpg',
  },
  showRedirectButton: {
    type: Boolean,
    default: true,
  },
  redirectText: {
    type: String,
    default: 'Show Promotions',
  },
  redirectLink: {
    type: String,
    default: '',
  },
  paddings: {
    type: String,
    default: 'px-4 py-16 sm:px-6 sm:py-24 lg:px-8',
  },
});

function redirect() {
  if (!props.redirectLink.length) {
    return;
  }

  const isExternal = /^(http|https):\/\//.test(props.redirectLink) &&
      !props.redirectLink.includes(window.location.hostname);

  if (isExternal) {
    window.open(props.redirectLink, '_blank');
  } else {
    router.push(props.redirectLink);
  }
}

</script>

<style scoped>

</style>
