import {AbstractWizzardService} from "@/theconcept/wizzard/base/abstract_wizzard_service";
import {WizzardType, WizzardTypeValues} from "@/theconcept/wizzard/base/wizzard.interface";
import {WizzardStepStatus} from "@/theconcept/wizzard/base/abstract_wizzard";
import {StepSummaryDataInterface} from "@/theconcept/wizzard/base/wizzard_summary";
import i18n from "@/i18n";

export class WizzardGeneralOffer extends AbstractWizzardService {
	private _offerType: WizzardType|null = null;
	private _offerTypes: any;


	get offerType(): WizzardType | null {
		return this._offerType;
	}

	set offerType(value: WizzardType | null) {
		this._offerType = value;
		this.resetProjectTypes();
	}

	hasOfferType(): boolean {
		return this._offerType !== null;
	}

	getStepsPath(): string {
		return 'general';
	}

	get offerTypes(): Array<{ wizzardType: string; source: string }> {
		if (this._offerTypes && this._offerTypes.length > 0) {
			return this._offerTypes;
		}

		const sources: Array<{ wizzardType: string; source: string }> = [];

		sources.push({ wizzardType: '', source: '' })
		WizzardTypeValues.forEach((wizzardType) => {
			const offerType = this.getTranslation(`wizzard.${wizzardType}.progressbar`);
			if (offerType) {
				sources.push({ wizzardType, source: offerType });
			}
		});

		this._offerTypes = sources;
		return this._offerTypes;
	}

	getWizzardName(): string {
		if (this.name && this.name.length > 0) {
			return this.name;
		}

		if (this.getType() && this.getType().length > 0) {
			return i18n.global.t('wizzard.' + this.getType() + '.title');
		}
		return i18n.global.t('wizzard.' + WizzardType.GENERAL_OFFER + '.title');
	}

	getType(): WizzardType {
		if (this.hasOfferType()) return this._offerType as WizzardType;
		return WizzardType.GENERAL_OFFER;
	}

	getStepComponentMap(): Record<number, string> {
		return {
			1: 'WizzardGeneralInquiryComponent',
			2: 'base/WizzardCompanyDetailsComponent',
			3: 'base/WizzardProjectServicesComponent',
			4: 'base/WizzardServiceSelectionComponent',
			5: 'base/WizzardBudgetPriorityComponent',
			6: 'base/WizzardContactInfoComponent',
		};
	}

	getStepTitle(step: number): string {
		switch (step) {
			case 1:
				return this.getTranslation('wizzard.general_offer.choose_offer_type.progressbar');
			case 2:
				return this.getTranslation('wizzard.base.company_details.progressbar');
			case 3:
				return this.getTranslation('wizzard.base.project_details.progressbar');
			case 4:
				return this.getTranslation(this.getStep4Title());
			case 5:
				return this.getTranslation('wizzard.base.budget_timeline.progressbar');
			case 6:
				return this.getTranslation('wizzard.base.contact.progressbar');
			default:
				return '';
		}
	}

	getStepsStatusInfo(): Record<number, WizzardStepStatus> {
		return {
			1: {
				step: 1,
				title: 'wizzard.general_offer.choose_offer_type.progressbar',
				description: 'wizzard.general_offer.choose_offer_type.description',
				current: this.currentStep === 1,
			},
			2: {
				step: 2,
				title: 'wizzard.base.company_details.progressbar',
				description: 'wizzard.base.company_details.description',
				current: this.currentStep === 2,
			},
			3: {
				step: 3,
				title: 'wizzard.base.project_details.progressbar',
				description: 'wizzard.base.project_details.description',
				current: this.currentStep === 3,
			},
			4: {
				step: 4,
				title: this.getStep4Title(),
				description: `wizzard.${this.getType()}.description`,
				current: this.currentStep === 4,
			},
			5: {
				step: 5,
				title: 'wizzard.base.budget_timeline.progressbar',
				description: 'wizzard.base.budget_timeline.description',
				current: this.currentStep === 5,
			},
			6: {
				step: 6,
				title: 'wizzard.base.contact.progressbar',
				description: 'wizzard.base.contact.description',
				current: this.currentStep === 6,
			},
		};
	}

	private getStep4Title() {
		let type: WizzardType = this.getType();

		if (!type || type.length === 0) {
			type = WizzardType.GENERAL_OFFER;
		}

		return `wizzard.${type}.progressbar`;
	}

	getWizzardSummaryDataByStep(step: number): StepSummaryDataInterface | undefined {
		switch (step) {
			case 1:
				return {
					step: 1,
					title: 'wizzard.general_offer.choose_offer_type.progressbar',
					description: this.offerType ?? '', //TODO FIX ME
				};
			case 2:
				return {
					step: 2,
					title: 'wizzard.base.company_details.progressbar',
					description: this.getCompanyDetails(),
				};
			case 3:
				return {
					step: 3,
					title: 'wizzard.base.project_details.progressbar',
					description: this.data?.projectDescription ?? '',
				};
			case 4:
				return {
					step: 4,
					title: this.getStep4Title(),
					description: this.getServicesSummaryDescription(),
				};
			case 5:
				return {
					step: 5,
					title: 'wizzard.base.budget_timeline.progressbar',
					description: this.getBudgetAndTimeSummaryDescription(),
				};
			case 6:
				return {
					step: 6,
					title: 'wizzard.base.contact.progressbar',
					description: this.getContactSummaryDescription(),
				};
			default:
				return undefined;
		}
	}

	validateStep(step: number): boolean {
		switch (step) {
			case 1:
				return this.hasOfferType();
			case 2:
				return !!this.companyDetails;
			case 3:
				return !!this.data?.projectType;
			case 4:
				return !!(this.data?.services && this.data?.services.length > 0);
			case 5:
				return !!this.data?.priorityType;
			case 6:
				return !!this.data?.contactInfo;
			default:
				return super.validateStep(step);
		}
	}
}