<template>
  <div v-if="!hasCookiesAccepted"
		 style="overflow: auto; position: fixed; bottom: 14px; z-index: 100; width: -webkit-fill-available; width: -moz-available; width: 100%;">

		<div v-if="showButton === true" @click="toggleDialog"></div>

		<div v-show="dialogVisible" id="cookiesComponent"
			 class="" data-aos="fade-up" data-aos-anchor="#cookiesComponent">
			<div id="test1" class="mx-auto max-w-4xl">
				<div class="float-right mt-4 mr-12">
					<XMarkIcon aria-hidden="true" class="w-4 absolute z-20 text-gray-400" @click="cancelDialog"/>
				</div>
				<div class="bg-tc-bglight text-white p-6 mx-4 rounded-2xl">
					<div class="mt-3 text-center sm:mt-0 sm:ml-4">
						<h1 id="modal-headline" class="text-xl leading-6 font-medium text-gray-300">
							{{ t("modal.CookiesDialog.CookiesText") }}
						</h1>
						<div v-if="showButton === true" class="mt-2 mb-0" @click="toggleInformation">
							<p class="text-sm text-gray-300">
								{{ t("modal.CookiesDialog.CookiesGuideline") }}
							</p>
							<div v-show="informationVisible" class="mt-4 overflow-auto max-h-56">
                <ul>
                  <li class="text-left text-sm text-gray-300 overflow-hidden">
                    <span class="">{{ t("modal.CookiesDialog.CookiesTitle") }}</span>
                    <div class="mb-5 break-all">
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail1")
                        }}</p>
                      <p class="text-md mb-2">{{ t("modal.CookiesDialog.CookiesDetail2") }}</p>
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail3")
                        }}</p>
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail4")
                        }}</p>
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail5")
                        }}</p>
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail6")
                        }}</p>
                      <p class="font-medium text-md mb-2">{{
                          t("modal.CookiesDialog.CookiesDetail7")
                        }}</p>
                      <ol>
                        <li><span>{{ t("modal.CookiesDialog.CookiesTitle2") }}</span>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail1") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail2") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail3") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail4") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail5") }}</p>
                          <p class="font-medium text-md mb-2"><strong>{{
                              t("modal.CookiesDialog..Cookies2TitelDetail6")
                            }}</strong>
                            {{ t("modal.CookiesDialog..Cookies2Detail6") }}</p>
                          <p class="font-medium text-md mb-2 break-all">
                            <strong>{{ t("modal.CookiesDialog.Cookies2TitelDetail7") }}</strong>
                            {{ t("modal.CookiesDialog..Cookies2Detail7") }}</p>
                          <p class="font-medium text-md mb-2"><strong>{{
                              t("modal.CookiesDialog..Cookies2TitelDetail8")
                            }}</strong>
                            {{ t("modal.CookiesDialog..Cookies2Detail8") }}</p>
                          <p class="font-medium text-md mb-2"><strong>{{
                              t("modal.CookiesDialog..Cookies2TitelDetail9")
                            }}</strong>
                            {{ t("modal.CookiesDialog..Cookies2Detail9") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies2Detail10") }}</p>
                          <ul class="list-disc list-inside text-md font-bold"
                              style="list-style-type: disc; list-style-position: inside;">
                            <li><span class="font-medium">{{
                                t("modal.CookiesDialog.Cookies2List1Item1")
                              }}</span></li>
                            <li><span class="font-medium">{{
                                t("modal.CookiesDialog.Cookies2List1Item2")
                              }}</span></li>
                            <li><span class="font-medium">{{
                                t("modal.CookiesDialog.Cookies2List1Item3")
                              }}</span></li>
                          </ul>
                        </li>
                        <li><span class="font-medium">{{
                            t("modal.CookiesDialog.Cookies3Title")
                          }}</span>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies3Detail") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.Cookies3Detail2") }}</p>
                          <ol>
                            <li><span>{{ t("modal.CookiesDialog.Cookies3InnerTitle1") }}</span>
                              <p class="font-medium text-md mb-2">
                                {{ t("modal.CookiesDialog.Cookies3InnerDetail1") }}</p>
                            </li>
                            <li><span>{{ t("modal.CookiesDialog.Cookies3InnerTitle2") }}</span>
                              <p class="font-medium text-md mb-2">
                                {{ t("modal.CookiesDialog.Cookies3InnerDetail2") }}</p>
                            </li>
                            <li><span>{{ t("modal.CookiesDialog.Cookies3InnerTitle3") }}</span>
                              <p class="font-medium text-md mb-2">
                                {{ t("modal.CookiesDialog.Cookies3InnerDetail3") }}</p>
                            </li>
                            <li><span>{{ t("modal.CookiesDialog.Cookies3InnerTitle4") }}</span>
                              <p class="font-medium text-md mb-2">
                                {{ t("modal.CookiesDialog.Cookies3InnerDetail4") }}</p>
                            </li>
                            <li><span>{{ t("modal.CookiesDialog.Cookies3InnerTitle5") }}</span>
                              <p class="font-medium text-md mb-2">
                                {{ t("modal.CookiesDialog.Cookies3InnerDetail5") }}</p>
                            </li>
                          </ol>
                        </li>
                        <li><span>{{ t("modal.CookiesDialog.DeleteCookiesTitle") }}</span>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.DeleteCookiesDetail") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.DeleteCookiesDetail1") }}</p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.DeleteCookiesDetail2") }}</p>
                          <p class="font-medium text-md">
                            <span>{{ t("modal.CookiesDialog.DeleteCookiesDetail3") }}</span></p>
                          <p class="font-medium text-md">
                            <span>{{ t("modal.CookiesDialog.DeleteCookiesDetail4") }}</span></p>
                          <p class="font-medium text-md">
                            <span>{{ t("modal.CookiesDialog.DeleteCookiesDetail5") }}</span></p>
                          <p class="font-medium text-md">
                            <span>{{ t("modal.CookiesDialog.DeleteCookiesDetail6") }}</span></p>
                          <p class="font-medium text-md mb-2">
                            {{ t("modal.CookiesDialog.DeleteCookiesDetail7") }}</p>
                        </li>
                        <li><span>{{ t("modal.CookiesDialog.DataProtectionCookiesTitle") }}</span>
                          <p class="font-medium text-md mb-2">{{
                              t("modal.CookiesDialog.DataProtectionCookiesDetail")
                            }}</p>
                          <p class="font-medium text-md mb-2">{{
                              t("modal.CookiesDialog.DataProtectionCookiesDetail2")
                            }}</p>
                        </li>
                      </ol>
                    </div>
                  </li>
                </ul>
								<p class="text-sm text-gray-300 mb-2">{{
										t("modal.CookiesDialog.PrivacyInformation")
									}}</p>
								<div class="mx-auto mb-12">
									<router-link to="/privacy-policy">
										<div class="mt-2 btn bg-top px-10 text-gray-200 bg-tc-bglightest hover:bg-tc-bglighest">
											Privacy
											Policcy
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-0 justify-center mx-auto px-4 pb-0 sm:px-6 sm:flex sm:flex-row-reverse">
						<div class="text-center">
							<div class="mt-4 mb-2">
								<div class="text-sm text-center mx-4 md:mx-16 lg:mx-20 text-gray-300">
									{{ t("modal.CookiesDialog.textAllCookies") }}
								</div>
							</div>
							<div class="mt-2 btn bg-top px-10 text-gray-900 bg-tc-green hover:bg-tc-green"
								 @click="confirm(true)">accept
								all
							</div>
						</div>
						<div class="text-center">
							<div class="mt-4 mb-2">
								<div class="text-sm text-center mx-4 md:mx-16 lg:mx-20 text-gray-300">
									{{ t("modal.CookiesDialog.textEssentialCookies") }}
								</div>
							</div>
							<div class="mx-auto">
								<div class="mt-2 btn bg-top px-10 text-gray-900 bg-tc-blue hover:bg-tc-blue"
									 @click="confirm(false)">accept
									essentiell
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref, toRef, onMounted, watch} from 'vue';
import useCustomStore, {ScriptInterface} from "@/composable/custom/useCustomStore";
import useTranslation from "@/composable/translation/useTranslation";
import {XMarkIcon} from '@heroicons/vue/24/outline';

export default defineComponent({
	name: 'CookiesDialog',
	components: {
    XMarkIcon,
	},
	props: {
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: true},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);
		const informationVisible = ref(visible.value);
		const {
      getCookies,
      setCookies,
      setCookieDetails,
      createScriptElement,
      appendScriptElement,
      isFullCookieConsentAllowed,
      removeScripts,
      setIsCookieScriptsDeleted,
      isCookieScriptsDeleted,
		} = useCustomStore();
		const cookies = ref(getCookies);
		const {t,} = useTranslation();
    const hasCookiesAccepted = ref(true);
    const cookieScriptList = ref<Array<ScriptInterface>>();

    function initCookieScriptList(force = false) {
      if (!cookieScriptList.value || force) {
        if (process.env.NODE_ENV === 'production') {
          cookieScriptList.value = [
            {
              'src': 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
              'arguments': {'async': true},
            },
            {
              'src': '',
              'arguments': {},
              'content': '\t\twindow.dataLayer = window.dataLayer || [];\n' +
                  '\t\tfunction gtag(){dataLayer.push(arguments);}\n' +
                  '\t\tgtag(\'js\', new Date());\n' +
                  '\t\tgtag(\'config\', "' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID + '");\n' +
                  '\t\tgtag(\'consent\', "default", {\n' +
                  '\'ad_user_data\': \'denied\',' +
                  '\'ad_personalization\': \'denied\',' +
                  '\'ad_storage\': \'denied\',' +
                  '\'analytics_storage\': \'denied\',' +
                  '});\n' +
                  '\t\tgtag(\'consent\', "update", {\n' +
                  '\'ad_user_data\': \'granted\',' +
                  '\'ad_personalization\': \'granted\',' +
                  '\'ad_storage\': \'granted\',' +
                  '\'analytics_storage\': \'granted\',' +
                  '});',
            },
            {
              'src': '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
              'arguments': {'async': true},
            },
          ];
        } else {
          cookieScriptList.value = [
            // {
            //   'src': 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
            //   'arguments': {'async': true},
            // },
            {
              'src': '',
              'arguments': {},
              'content': '\t\twindow.dataLayer = window.dataLayer || [];\n' +
                  '\t\tfunction gtag(){dataLayer.push(arguments);}\n' +
                  '\t\tgtag(\'js\', new Date());\n' +
                  '\t\tgtag(\'config\', "' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID + '");\n' +
                  '\t\tgtag(\'consent\', "default", {\n' +
                  '\'ad_user_data\': \'denied\',' +
                  '\'ad_personalization\': \'denied\',' +
                  '\'ad_storage\': \'denied\',' +
                  '\'analytics_storage\': \'denied\',' +
                  '});\n' +
                  '\t\tgtag(\'consent\', "update", {\n' +
                  '\'ad_user_data\': \'granted\',' +
                  '\'ad_personalization\': \'granted\',' +
                  '\'ad_storage\': \'granted\',' +
                  '\'analytics_storage\': \'granted\',' +
                  '});',
            },
            {
              'src': '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
              'arguments': {'async': true},
            },
          ];
        }
      }
    }

		function checkAdditionalScripts() {
			if (isFullCookieConsentAllowed()) {
        initCookieScriptList();
        if (!cookieScriptList.value) {
          return;
        }
				cookieScriptList.value.forEach((value) => {
					let scriptSrc = '';
					if (value.src) {
						scriptSrc = value.src;
					}
					let scriptArguments = {};
					if (value.arguments) {
						scriptArguments = value.arguments;
					}
					let scriptContent = '';
					if (value.content) {
						scriptContent = value.content;
					}
					let scriptType = '';
					if (value.type) {
						scriptType = value.type;
					}
					const scriptElement = createScriptElement(scriptSrc, scriptArguments, scriptContent, scriptType);
					appendScriptElement(scriptElement);
        });
        setIsCookieScriptsDeleted(false);
      } else {
        removeAdditionalScripts();
      }
		}

		function checkCookiesAccepted() {
			const result = cookies.value === true;
			if (result) {
				checkAdditionalScripts();
			}
      hasCookiesAccepted.value = result;
      dialogVisible.value = !result;
		}

    function removeAdditionalScripts() {
      if (isCookieScriptsDeleted.value) {
        return;
      }
      initCookieScriptList();
      if (!cookieScriptList.value) {
        return;
      }
      removeScripts(cookieScriptList.value);
      cookieScriptList.value = undefined;
      setIsCookieScriptsDeleted(true);
    }

		function toggleDialog() {
			dialogVisible.value = !dialogVisible.value;
		}

		function toggleInformation() {
			informationVisible.value = !informationVisible.value;
		}

		function hideDialog() {
			dialogVisible.value = false;
		}

		const cancelDialog = () => {
      hideDialog();
			emit('cancelConfirm');
		};

		function confirm(essential = false) {
      hideDialog();
			setCookies(true);
			if (essential) {
				setCookieDetails({all: true, minimal: false});
			} else {
				setCookieDetails({all: false, minimal: true});
			}
			checkAdditionalScripts();
			emit('confirm');
		}

    watch(() => getCookies.value, (newValue: any) => {
      setCookies(newValue);
      checkCookiesAccepted();
    });

		onMounted(() => {
			checkCookiesAccepted();
		});

		return {
			dialogTitle,
			dialogDescription,
			dialogVisible,
			informationVisible,
			toggleDialog,
			toggleInformation,
			confirm,
			cancelDialog,
			hasCookiesAccepted,
			t,
			cookies
		}
	},
})
</script>

<style scoped>

</style>
