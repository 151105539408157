import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.webp'


const _hoisted_1 = { class: "flex-1 w-1 px-2 mx-2" }
const _hoisted_2 = { class: "text-md sm:text-2xl font-bold text-left" }
const _hoisted_3 = { class: "flex-none hidden px-2 mx-2 lg:flex" }
const _hoisted_4 = { class: "flex items-stretch" }
const _hoisted_5 = { class: "flex ml-3 mb-1 rounded-3xl" }
const _hoisted_6 = { class: "visible lg:hidden" }
const _hoisted_7 = { class: "flex -mr-1 sm:mr-1 rounded-3xl" }
const _hoisted_8 = {
  class: "justify-center",
  style: {"flex-basis":"100%","align-items":"unset"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeartIcon = _resolveComponent("HeartIcon")!
  const _component_LightBulbIcon = _resolveComponent("LightBulbIcon")!
  const _component_BuildingOfficeIcon = _resolveComponent("BuildingOfficeIcon")!
  const _component_LanguageSelector = _resolveComponent("LanguageSelector")!
  const _component_Bars3Icon = _resolveComponent("Bars3Icon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_light_bulb_icon = _resolveComponent("light-bulb-icon")!
  const _component_building_office_icon = _resolveComponent("building-office-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flex-wrap fixed navbar content-center font-extrabold rounded-3xl m-4 mt-1 shadow-lg backdrop-filter backdrop-blur-lg text-white top-3 z-10 transition-colors duration-300 ease-in-out', _ctx.getHighlightColor]),
    style: {"width":"-webkit-fill-available"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "w-8 sm:w-8 sm:h-8 mr-4" }, [
        _createElementVNode("img", {
          loading: "lazy",
          alt: "theconcept Logo",
          src: _imports_0
        })
      ], -1)),
      _createVNode(_component_router_link, {
        to: "/",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.CompanyName) + " " + _toDisplayString(_ctx.CompanySureName), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "btn btn-ghost btn-sm rounded-btn",
          to: "/solutions"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HeartIcon, {
              class: "inline-block w-5 mb-1 mr-0 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.SolutionBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost btn-sm rounded-btn",
          to: "/vision"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LightBulbIcon, {
              class: "inline-block w-5 mr-0 mb-1 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.VisionBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost btn-sm rounded-btn",
          to: "/company"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BuildingOfficeIcon, {
              class: "inline-block w-4 mb-1 mr-0 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.CompanyBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost bg-tc-green text-white btn-sm mx-4 mr-0 px-6 rounded-btn",
          to: "/contact"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("navbar.ContactBtn")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LanguageSelector, {
          "css-class": "btn btn-ghost btn-sm rounded-btn px-0 pt-1 pb-1",
          "show-only-keys": true
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LanguageSelector, {
          "css-class": "btn btn-ghost btn-sm rounded-btn px-0 pt-1 pb-1",
          "show-only-keys": true
        })
      ]),
      _withDirectives(_createElementVNode("button", {
        "aria-label": "Open Menu",
        class: "btn btn-square btn-ghost",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
      }, [
        _createVNode(_component_Bars3Icon, {
          class: "inline-block w-6 h-6 stroke-current",
          "aria-hidden": "true"
        })
      ], 512), [
        [_vShow, !_ctx.showMenu]
      ]),
      _withDirectives(_createElementVNode("button", {
        "aria-label": "Close Menu",
        class: "btn btn-square btn-ghost",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_XMarkIcon, {
          class: "h-6 w-6",
          "aria-hidden": "true"
        })
      ], 512), [
        [_vShow, _ctx.showMenu]
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", {
        class: _normalizeClass(["grid grid-cols-1 my-36", {'hidden': !_ctx.showMenu, '': _ctx.showMenu}])
      }, [
        _createVNode(_component_router_link, {
          class: "btn btn-ghost rounded-btn col-1 m-4 mb-4 px-12",
          to: "/solutions",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HeartIcon, {
              class: "inline-block w-5 mb-1 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.SolutionBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost border border-white rounded-btn col-1 m-4",
          to: "/vision",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_light_bulb_icon, {
              class: "inline-block w-5 mb-1 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.VisionBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost border border-white rounded-btn col-1 m-4",
          to: "/company",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_building_office_icon, {
              class: "inline-block w-5 mb-1 stroke-current",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t("navbar.CompanyBtn")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "btn btn-ghost bg-tc-tcBtn border-white mx-4 mr-0 px-6 rounded-btn col-1 m-4",
          to: "/contact",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("navbar.ContactBtn")), 1)
          ]),
          _: 1
        })
      ], 2)
    ])
  ], 2))
}